import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css' ;

import NaviBar from './Components/Navibar';

import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';

import {Home} from './Home/Home';
import {Contact} from './Contacts/Contact';
import {About} from './About/About';
import Footer from './Components/Footer';

function App() {
  return (
    <>
    <Router>
      <NaviBar/>

      <Routes>
        <Route exact path="/" element={<Home/>}/>
        <Route exact path="/contact" element={<Contact/>}/>
        <Route exact path="/about" element={<About/>}/>

      </Routes>
    </Router>
    <Footer />
    </>
  )
}

export default App;
