import React from 'react';
import Slider from '../Components/Slider'
import Parallax from '../Components/paralax'
import {Container, Row, Col, Card} from 'react-bootstrap';
import psheno from '../Assets/psheno.jpg';
import sky from '../Assets/sky.jpg';
import hand from '../Assets/hand.jpg';
import wind from '../Assets/wind.jpg';
import {TitleH4, TitleH5, Paragraph, Image} from './styled';

export const Home = () => (
    <>
    <Slider />
        <Container style={{marginTop: '25px', marginBottom: '25px'}}>
            <Row>
                <Col>
                    <Card style={{width: '18rem', marginLeft: 'auto', marginRight:'auto'}}>
                    <Card.Img variant="top" src={psheno}/>
                    <Card.Body>
                        <TitleH4>Співпраця</TitleH4>
                        <Paragraph>
                            Головною нашею метою, є об'єднання агровиробників країни для
                            співпраці та обміну досвідом 
                        </Paragraph>
                       
                    </Card.Body>
                </Card>
                </Col>

                <Col>
                    <Card style={{width: '18rem', marginLeft: 'auto', marginRight:'auto'}}>
                    <Card.Img variant="top" src={sky}/>
                    <Card.Body>
                        <TitleH4>Партнерство</TitleH4>
                        <Paragraph>
                            Для нас кожен агровиробник є партнером, тому кожному намаєгомося надати підтримку 
                        </Paragraph>
                    </Card.Body>
                </Card>
                </Col>

                <Col>
                    <Card style={{width: '18rem', marginLeft: 'auto', marginRight:'auto'}}>
                    <Card.Img variant="top" src={hand}/>
                    <Card.Body>
                        <TitleH4>Підтримка</TitleH4>
                        <Paragraph>
                            Надання всебічної допомоги і підтримки у різних питаннях стосовно сільгосп виробництва
                        </Paragraph>
                    </Card.Body>
                </Card>
                </Col>
            </Row>
        </Container>

        <Parallax/>
        <Container style={{marginBottom: '30px', marginTop: '30px'}}>
            <Row>
                <Col md={7}>
                    <br />
                    <Image src={wind} alt={wind}/>
                </Col>

                <Col md={5}>
                    <TitleH5>Запровадження новітніх технологій</TitleH5>
                    <br />
                    <Paragraph>
                    Сучасний розвиток будь-якого агро підприємства, неможливий без впровадження та використання інновацій та техніко-технологічних рішень,
                    оскільки рівень активізації останніх визначають загальний рівень конкурентоспроможності як на
                    внутрішньому, так і зовнішньому ринках. Адже всеохоплююче впровадження інновацій сприяє підвищенню
                    продуктивності праці, економії різних видів ресурсів,
                    скороченню витрат та зниженню собівартості аграрнопродовольчої продукції, нарощуванню обсягів
                     і підвищенню ефективності сільськогосподарського виробництва, що впливає на залучення інвестицій. 
                    </Paragraph>

                </Col>
            </Row>

        </Container>
    </>
)