import React from 'react';
import { Container } from 'react-bootstrap';
import { ParallaxProvider } from 'react-scroll-parallax';
import kolosok from '../Assets/kolosok.jpg';
import styled from 'styled-components';

const Styles = styled.div `
  .par {
    background: URL(${kolosok}) no-repeat fixed bottom;
    background-size: ConvolverNode;
    color: #efefef;
    height: 400px;
    position: relative;
    z-index: -2;
    @media (max-width: 520px) {
      height: 500px;
      }
  }
  .overlay{
    background-color: #000;
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
  }
.title{
    padding-top: 30px;
    font-family: Merriweather;
    font-size: 30px;
    font-weight: 700;
    text-align: justify;

  }
.postList{
    padding-top: 15px;
    font-family: Merriweather;
    font-size: 20px;
    font-weight: 400;
    text-align: justify;
    @media (max-width: 520px) {
      font-family: Merriweather;
      font-size: 18px;
      font-weight: 400;
      }
}

`;


function Parallax() {
  
    return (
      <Styles>
      <ParallaxProvider>
        <div className="par">
          <div className="overlay">
        <Container>
        <h3 className="title">Залучення інвестицій</h3>
        <p className="postList">Одним із найвагоміших важелів впливу на розвиток
           сільськогосподарсьго виробництва, є залучення інвестицій.
            Ефективне сільськогосподарське виробництво
              неможливе без масштабних інвестицій, які являють собою
                найважливіший фактор економічного зростання й відновлення,
                що забезпечує можливість модернізації діючих виробництв,
                  сучасних систем організації та управління економічними процесами,
                    якісного відновлення ринкової інфраструктури.
          </p>
    </Container>
    </div>
    </div>
      </ParallaxProvider>
      </Styles>
    );
  
}

export default Parallax;