import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import uborka from '../Assets/uborka.jpg';
import stog from '../Assets/stog.jpg';
import { TitleH1, Image, Paragraph } from './styled';

export const About = () => (
    <Container style={{marginBottom: '30px', marginTop: '30px'}}>
        
        <TitleH1>Діяльність Агро спілки</TitleH1> <br />
           
    <Row>
        <Col md={6}>
            <Image src={uborka} alt={uborka}/>
        </Col>

        <Col md={6}>
            
            <Paragraph>
                    З розвитком інтеграційних процесів українським агро підприємствам
                все інтенсивніше доводиться адаптуватися до нових умов
                господарювання. Актуальним стає завдання забезпечити здатність
                вітчизняних підприємств конкурувати з іноземними
                підприємствами, менеджмент та техніко-технологічний рівень
                яких значно вищі за український.
            </Paragraph>
            <br />
            <Paragraph>
                    Для здійснення аграрними підприємствами господарської діяльності за
                ринкових умов необхідно передбачати обов'язковість створення відповідних
                умов правового та організаційно-економічного характеру.
            </Paragraph>
            <br />

        </Col>

        
    </Row>

    <Row>
        

        <Col md={6}>
        <Paragraph>
                    Головна проблема аграрних підприємств, це недостатня ресурсна
                база як матеріально-технічна, так і фінансова, а також
                збільшення з боку державних органів тиску через
                застосування та введення дозвільних заходів. Так до теперішнього
                часу відсутня державна програма розвитку аграрного бізнесу.
                Зрозуміло, що гранично напружений державний
                бюджет, за нинішніх умов, їх джерелом стати не може. А
                кредитні ресурси незначні і до того ж досить складно
                реалізовані за постійної інфляції.
            </Paragraph>
            <br />
            <Paragraph>
                Тому, наша Аграрна спілка займається наданням всебічної допомоги вітчизняним 
                агро виробникам. А саме, надання правової та юридичної допомиги, а також 
                допомогає отримати аграрним підприємствам фінансову допомогу як в 
                державному так і приватному секторі.
            </Paragraph>

        </Col>

        <Col md={6}>
            <Image src={stog} alt={stog}/>
        </Col>

        
    </Row>

</Container>
)