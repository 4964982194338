import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { TitleH1, Section, Title, DivForm, FormBlock, Input, ButtonSend,
         DivMapH2, DivMap, Iframe, BlockPhone, PhoneImg } from './styled';
import telephone from '../Assets/telephone.png';

export const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', form.current, 'YOUR_PUBLIC_KEY')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  };

    return (
  
    <Section>

        <TitleH1>Контакти</TitleH1>
             
         <DivForm>

            <FormBlock>

                    <div>
                        <Title>Форма зворотнього зв'язку</Title>
                    </div>

                    <form ref={form} onSubmit={sendEmail}>
                            <div>
                                <label class="form-label">Ім'я </label>
                                <Input type="text" name="name" class="form-control"/>
                            </div>
                            <div>
                                <label class="form-label">Email </label>
                                <Input type="email" name="email" class="form-control"/>
                            </div>
                            <div>
                                <label class="form-label">Тема повідомлення </label>
                                <Input type="text" name="subject" class="form-control"/>
                            </div>
                            <div>
                                <label class="form-label">Повідомлення</label>
                                <textarea name="message" class="form-control"/>
                            </div>


                            <ButtonSend type="submit" value="Відправити" class="btn btn-primary"/>
                        </form>

                </FormBlock>
                <br />
                <FormBlock>

                    <div>
                        <Title>Наші телефони:</Title>
                    </div>

                     <BlockPhone>
                        <li style={{color: '#fff'}}><PhoneImg src={telephone} alt={telephone}/> 057 345 67 12</li>
                        <li style={{paddingTop: '15px', color: '#fff'}}><PhoneImg src={telephone} alt={telephone}/> 066 255 45 32</li>
                        <li style={{paddingTop: '15px', color: '#fff'}}><PhoneImg src={telephone} alt={telephone}/> 063 117 63 98</li>
                    </BlockPhone>

                </FormBlock>
           
        </DivForm>
        
            <DivMapH2><u>Наша адреса</u>: просп. Героїв Харкова буд. 247, оф. 23 місто Харків</DivMapH2>

                <DivMap>                   
                    <Iframe src="https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d2422.198492720368!2d36.31232980273056!3d49.96954746159598!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sru!2sua!4v1657020522057!5m2!1sru!2sua"></Iframe>
                </DivMap>       

</Section>

  
  );
};


