import React from 'react';
import { Nav, Navbar, Button, Container, Modal, Form } from 'react-bootstrap';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import {useState} from 'react';
import logo from '../Assets/logo.png'

const Styles = styled.div `
    a, .navbar-brand, .navbar-nav, .nav-link{
        color: #ffffff;
        &:hover {
            color: #adb1b8
        }
    }
`

export default function NaviBar(){

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);

    const onSubmit = () => setShow(false);


    return(
    <>
        <Styles>
        <Navbar fluid style={{backgroundColor: '#14A800', color: '#fff'}}>
        <Container>
        <Navbar.Brand><img src={logo} alt={logo}/></Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
            <Navbar.Collapse id="responsive-navbar-nav"/>
                <Nav className="me-auto">
                    <Nav.Link><Link to="/">Головна</Link></Nav.Link>
                    <Nav.Link><Link to="contact">Контакти</Link></Nav.Link>
                    <Nav.Link><Link to="about">Про нас</Link></Nav.Link>
                </Nav>

    </Container>
    </Navbar>
    </Styles>

    <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
            <Modal.Title>Log in</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form>
                <Form.Group controlId="fromBasicEmail">
                <Form.Label>Email Adress</Form.Label>
                <Form.Control type="email" placeholder="Enter email"/>
                <Form.Text className="text-muted">We'll never share your email</Form.Text>
                </Form.Group>

                <Form.Group controlId="fromBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control type="password" placeholder="Enter password"/>
                </Form.Group>

                <Form.Group controlId="fromBasicCheckbox">
                <Form.Check type="checkbox" label="Remember me"/>
                </Form.Group>
            </Form>
        </Modal.Body>
        <Modal.Footer closeButton>
            <Button onClick={onSubmit}>Submit</Button>
        </Modal.Footer>
    </Modal>
    </>

)}