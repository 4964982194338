import styled from 'styled-components';


export const TitleH4 = styled.h4`
    font-family: Merriweather;
    font-size: 28px;
    font-weight: 400;
    text-align: justify;
`;

export const TitleH5 = styled.h5`
    font-family: Merriweather;
    font-size: 30px;
    font-weight: 700;
    text-align: center;
`;

export const Paragraph = styled.p`
    font-family: Merriweather;
    font-size: 20px;
    font-weight: 400;
    text-align: justify;
`;
export const Image = styled.img`
    display: block;
    margin: 0 auto; 
    max-width:100%;
    height: 400px;
`;

