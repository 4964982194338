import styled from 'styled-components';
import lan from '../Assets/lan.jpg';

export const Section = styled.section`
    background: URL(${lan}) no-repeat fixed bottom;
    background-size: ConvolverNode;
    background-repeat: no-repeat;
    height: auto;
    width: 100%;
`;

export const TitleH1 = styled.h1`
padding-top: 30px;
    font-family: Merriweather;
    font-size: 40px;
    font-weight: 700;
    color: #ffffff;
    padding-bottom: 70px;
    margin-left: 25px;
    text-decoration: underline;
`;

export const DivForm = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    @media (max-width: 820px) {
        flex-direction: column;
    } 
`;

export const FormBlock = styled.div`
    background: #ACE1AF;
    margin-left: auto;
    margin-right: auto;
    width: 22em;
    height: 420px;
    padding: 20px;
    border: 1px solid #FFCC7F;
    border-radius: 10px;
`;

export const Title = styled.p`
    font-family: Merriweather;
    font-weight: 700;
    font-size: 24px;
    text-align: center;
    @media (max-width: 560px) {
        font-family: Merriweather;
        font-weight:700;
        font-size: 20px;
    }
`;

export const Input = styled.input`
   min-width: 300px;
`;

export const ButtonSend = styled.input`
   margin-top: 20px;
   //background-color: #FFBF73;
   color: #000000;
   border: 1px solid #ffffff;
   border-radius: 5px;
   &:hover {
            color: #14A800
        }
        &:active {
            color: #FF0800
        }
`;


export const DivMap = styled.div`
    overflow:hidden;
    padding-bottom:56.25%;
    position:relative;
    height:0;
`;

export const DivMapH2 = styled.h2`
    padding-top: 50px;
    margin-bottom: 25px;
    font-family: Merriweather;
    font-size: 28px;
    color: #ffffff;
    font-weight: 700;
    margin-left: 25px;

`;

export const BlockPhone = styled.ul`
    padding-top: 50px;
    font-family: Merriweather;
    font-size: 28px;
    font-weight: 700;
    text-align: center;
    text-decoration: none;
    list-style: none; 
`;

export const PhoneImg = styled.img`
    margin-right: 15px;
`;

export const Iframe = styled.iframe`
    border: 1px solid #8A496B;
    left:0;
    top:0;
    height:100%;
    width:100%;
    position:absolute;


`;
