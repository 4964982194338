import React from 'react';
import {Container} from 'react-bootstrap';
import styled from 'styled-components';

import logo from '../Assets/logo.png';
import logoDa from '../Assets/logoDA.png';

const Copyrate = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    color: #ffffff;
    font-family: Merriweather;
    font-size: 18px;
    font-weight: 400;
`;

const Footer = () => (
    <Container fluid style={{backgroundColor: '#14A800', color: '#000'}}>
        <Container style={{display: 'flex', justifyContent: 'Center'}}>
           <img src={logo} alt={logo}/>
        </Container>
            <Copyrate>
                <div>
                    <a href="https://yakha.com.ua/" >
                        <img src={logoDa} alt={logoDa}/>
                    </a>
                </div>
                <div>
                    <span>&#169; агроСпілка, 2022</span>
                </div>
            </Copyrate>
    </Container>
)
export default Footer;