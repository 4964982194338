import styled from 'styled-components';

export const TitleH1 = styled.h1`
    font-family: Merriweather;
    font-size: 28px;
    font-weight: 700;
    text-align: center;
`;

export const Image = styled.img`
    display: block;
     margin: 0 auto; 
    max-width:100%;
    height: 400px;
`;

export const Paragraph = styled.p`
    font-family: Merriweather;
    font-size: 24px;
    font-weight: 400;
    text-align: justify;
`;