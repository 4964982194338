import React from 'react';
import { Carousel } from 'react-bootstrap';
import maki from '../Assets/maki.jpg';
import pole from '../Assets/pole.jpg';
import podsolnux from '../Assets/podsolnux.jpg';
import styled from 'styled-components';


const Paragraph = styled.p`
    font-family: Merriweather;
    font-size: 24px;
    font-weight: 400;
    line-height: 1;
    @media (max-width: 560px) {
        font-family: Merriweather;
        font-size: 18px;
    }
`;

const BlockZag = styled.h1`
    font-family: Merriweather;
    font-size: 34px;
    font-weight: 700;
    text-decoration: underline;

    @media (max-width: 640px) {
     
        font-size: 20px;
        font-family: Merriweather;
        font-weight: 400;
        text-decoration: underline;
    }

`;

export default function Slider(){
    return(
       
    <Carousel>
       
        <Carousel.Item style={{'max-height': '600px'}}>

            <img className="d-block w-100" src={maki} alt="First slide"/>

                <Carousel.Caption>
                    <BlockZag>АГРО СПІЛКА</BlockZag>
                     
                    <Paragraph>об'єднання агро виробників, представників малого
                    і середнього бізнесу а також всеукраїнських правозахисних
                    організацій та кредитних фондів
                    </Paragraph>
                    </Carousel.Caption>
               
        </Carousel.Item>

        <Carousel.Item style={{'max-height': '600px'}}>

            <img className="d-block w-100" src={pole} alt="Second slide"/>

                <Carousel.Caption>
                    <BlockZag>ПРАВОВА ПІДТРИМКА</BlockZag>
                 
                    <Paragraph>надання правової підтримки, захист, представництво у державних
                    та інших установах найкращими вітчизняними та міжнародними
                    юридичними організаціями</Paragraph>
                </Carousel.Caption>
                
        </Carousel.Item>

        <Carousel.Item style={{'max-height': '600px'}}>

            <img className="d-block w-100" src={podsolnux} alt="Fre slide"/>

                <Carousel.Caption>
                    <BlockZag>ФІНАНСОВА ДОПОМОГА</BlockZag>
                   
                    <Paragraph>співпраця з баготьма фінансовими фондами та кредитними спілками
                    надає можливість сприяти в отриманні кредитів та траншів
                    для ведення виробництва</Paragraph>
                </Carousel.Caption>
                
        </Carousel.Item>
   
    </Carousel>
  
)}